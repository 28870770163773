import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import firebase from '../../firebase';
import Typography from '@material-ui/core/Typography';


const StyledTableCell = withStyles((theme) => ({

    
    head: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
    //   '&:nth-of-type(even)': {
    //     backgroundColor: theme.palette.action.disabledBackground,
    //   },
    },
  }))(TableRow);
  
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    paddingTop: 10
  },
  paper: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  
}));





const PledgeTable = () => {

const classes = useStyles();

  useEffect(()=> {

    var pledgesRef = firebase.database().ref('pledges');
    pledgesRef.on('value', function(data) {
        const value  = data.val();
        console.log('from value')
        console.log(value);
        if (value === null || typeof value === "undefined") return null;
        const keys = Object.keys(value);
        const values = Object.values(value);
        const valuesWithKeys = values.map(
          (value, i) =>
            ({
              amount:value.amount,
              firstName:value.firstName,
              lastName:value.lastName,
              id: keys[i]
            })
        );

        setPledges(valuesWithKeys)

        var changedAmounts = [];
        valuesWithKeys.filter(v => !isNaN(v.amount) ).map(p => changedAmounts.push(p.amount) );

        setPledges(changedAmounts);       

        let updatedAmount =  changedAmounts.reduce((a, n) => (a + Number(n)), 0);
         setTotal(updatedAmount);
  
         var uniqueAmounts = [...new Set(changedAmounts)];
         var uniqueAmountCounts = [];
         uniqueAmounts.map( am => { 
          console.log(am);
          uniqueAmountCounts[am] = getOccurrence(changedAmounts, am)
            
  
          });
  
          setPledgeCounts(uniqueAmountCounts);

    });

},[]);

    
   const [pledges, setPledges] = useState([]);
   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(10);
   const [total, setTotal] = useState(0);
   const[pledgeCounts,setPledgeCounts] = useState({})

 
   const handleChangePage = (event, newPage) => {
     setPage(newPage);
   };
 
   const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(event.target.value);
     setPage(0);
   };


   return (
       <div className={classes.paper}>     <TableContainer >
     <Typography variant="h5" style={style}>Pledges</Typography>

     <Table className={classes.table} stickyHeader aria-label="sticky table">
         <TableHead>
           <TableRow>
             <StyledTableCell >Amount ($)</StyledTableCell >
             <StyledTableCell  align="left">First Name</StyledTableCell >
             <StyledTableCell  align="left">Last Name</StyledTableCell >
             {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell>
             <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
           </TableRow>
         </TableHead>
         <TableBody>
         {pledges.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((pledge) => {
          return (
             <StyledTableRow key={pledge.id}>
               <StyledTableCell  component="th" scope="row">
                 {pledge.amount}
               </StyledTableCell >
               <StyledTableCell  align="left">{pledge.firstName}</StyledTableCell >
               <StyledTableCell  align="left">{pledge.lastName}</StyledTableCell >
             </StyledTableRow>
         )})}
         </TableBody>
       </Table>
     </TableContainer>
     <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={pledges.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </div>

   );
    

}



const style ={
    display: 'flex',
    justifyContent: 'center'
  }
  
  function getOccurrence(array, value) {
    return array.filter((v) => (v === value)).length;
  }
  
  
  


  export default PledgeTable;