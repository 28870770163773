import React, {useState, useEffect} from 'react'
import firebase, { auth } from 'firebase'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { Button } from '@material-ui/core'

function AuthTest() {
  const [state, setState] = useState({
    isSignedIn: false,
    uiConfig: 
    {
        signInFlow:'popup',
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID
        ],
        callbacks:
        {
   
            signInSuccess: () => setState(prev => ({...prev, isSignedIn:true}))
        }
    }
  })


useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
        setState(prev => ({...prev, isSignedIn:firebase.auth().currentUser}))
        console.log(firebase.auth().currentUser)
    })
}, [])

    return (
        <div>
            {state.isSignedIn ? (    <>       <Button
            type="submit"
            variant="contained"
            color="primary"
            // className={classes.submit}
            onClick={() => {
                firebase.auth().signOut()
                setState(prev => ({...prev, isSignedIn:false}))
            }}
          >
            Sign out
          </Button>
          <h2>Welcome {firebase.auth().currentUser.displayName}</h2>
          <img alt="profile picture" src={firebase.auth().currentUser.photoURL}/>
          </>
            ) :
             (
                 <h2>
             <StyledFirebaseAuth
               uiConfig={state.uiConfig}
               firebaseAuth={firebase.auth()} />
      
                </h2>
             )}
        </div>
    )
}

export default AuthTest
