import react from 'react'
import styles from '../pledgeCounts/pledgeCounts.module.css'
import React, {useState, useEffect, useRef} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import firebase from '../../../firebase';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Button from '@material-ui/core/Button';
import LinearProgress  from '@material-ui/core/LinearProgress';
import PledgeNotification from  '../../pledgeNotifcation/pledgeNotification'
import PledgeBarChart from '../../charts/Bar/pledgeBarChart'
import Header from '../../header/header'
toast.configure();




const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding:theme.spacing(5),
    width:'100%',
    borderRadius:20

  },

  video: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width:'100%',
    height:'50vh',
    borderRadius:20

  },

  banner: {
    color:"ghostwhite",
    backgroundColor:'#d35400',
    fontSize:25,
    padding:theme.spacing(1),
    marginBottom:theme.spacing(1),
    width:'100%',
    textAlign:'center'

  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0),
    
  },
  error: {
    color:'tomato'
    
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  option: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: '20px'
  },
  select: {
    margin: "20px 0px 0px 0px"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const pledgeStaticCounts = [
  {amount:50000, count:'0'},
  {amount:25000,count:'0'},
  {amount:20000,count:'0'},
  {amount:10000,count:'0'},
  {amount:5000,count:'0'},
  {amount:2500,count:'0'},
  {amount:2000,count:'0'},
  {amount:1500,count:'0'},
  {amount:1000,count:'0'},
  {amount:500,count:'0'},
  {amount:250,count:'0'},
  {amount:100,count:'0'}];





const PledgeCounts = () => {
  const classes = useStyles();
    const [pledges, setPledges] = useState([]);
    const [total, setTotal] = useState(0);
    const[pledgeCounts,setPledgeCounts] = useState([])
    const[notifiedPledges, setNotifiedPledges] = useState([]);

    const prevNotifications = usePrevious(notifiedPledges);
    const [noticies, setNoticies] = useStateWithLocalStorage(
      'notifications'
    );


  useEffect(()=> {

    var pledgesRef = firebase.database().ref('pledges');
    pledgesRef.on('value', function(data) {

       
        const value  = data.val();

        if (value === null || typeof value === "undefined") return null;
        const keys = Object.keys(value);
        const values = Object.values(value);
        const valuesWithKeys = values.map(
          (value, i) =>
            (
                
            
                {
              amount:value.amount,
              firstName:value.firstName,
              lastName:value.lastName,
              id: keys[i]
            })

          
        );

   

        setPledges(valuesWithKeys)

        var changedAmounts = [];
        var pledgeIds = []
        valuesWithKeys.filter(v => !isNaN(v.amount) ).map(p => {
        
         
            pledgeIds.push(p.id);
            changedAmounts.push(p.amount) 
            
        
        });

         var uniqueAmounts = [...new Set(changedAmounts)];
         uniqueAmounts.sort((a,b) => a-b);
         var uniqueAmountCounts = [];
         uniqueAmounts.map( am => { 

            let count = getOccurrence(changedAmounts, am);
            uniqueAmountCounts.push(
                {
                    bracket: am,
                    count: ''+count,
                    tot: am * count
                }
            );
            
            pledgeStaticCounts.find(psc => psc.amount == am).count = ''+count;


           });
 
           

var somehting = Object.keys(noticies);
var arryas = noticies.split(',');
var arrayX = localStorage.getItem('notifications').split(',');

pledgeIds.map( pi => {

            if(!arrayX.includes(pi))
            {
              let newNotification =  valuesWithKeys.find(v => v.id === pi);
              notify(money(newNotification.amount));
  
            }

           });

   
          setNotifiedPledges(pledgeIds);   
          setNoticies(pledgeIds);
          setPledgeCounts(uniqueAmountCounts);

   

    });

},[]);



return (
<Grid container direction='row' justify='flex-end v' alignItems="flex-end" spacing={4} style={{paddingTop:2}}>
  
 
  




<Grid item direction='column' justify="flex-start" alignItems="flex-end" item xs={12} sm={8} ><PledgeBarChart Goal={process.env.REACT_APP_FUNDING_GOAL}/>
</Grid>
<Grid item  xs={12} sm={4}>
{/* <Header/> */}
<div>

	<table >
		<thead>
			<tr>
				<th>Pledge Amount</th>
				<th>Pledge Count</th>
			</tr>
		</thead>
		<tbody>
            {
           

           pledgeStaticCounts.map((pc) => {

                         return <React.Fragment>
                         <tr key={`${pc.amount}-${pc.count}`}> 
                            <td><Button style={{width:115, fontWeight:400, fontSize:18, backgroundColor:'#00b894', color:'white'}} size='small' variant='contained' color='primary' width='100px'>{money(pc.amount)}</Button></td>
                            <td><Button style={{width:115, fontWeight:400, fontSize:18, backgroundColor:'#0984e3', color:'white'}} size='small' variant='contained' color='info' width='100px'>{pc.count.padStart(2, "0")}</Button></td>
                        </tr>
                        <Divider/>
                        </React.Fragment>
                            
                    })

            }
		</tbody>
	</table>
</div>

</Grid>


</Grid>

);


}

const showNotifcations = (pledges) =>
{
    pledges.map( v => { 
              
            notify(v.amount,'From Someone')
    
    })
}

function notify(amount)  {

  toast(<PledgeNotification PrimaryText={`${amount} Donation!`} SecondaryText={"From: Anonymous"}/>,'basic notification');

  }

function getOccurrence(array, value) {
    return array.filter((v) => (v === value)).length;
  }
  

  function money(amount)
  {
    return '$'+Number(amount).toMoney(0,'.',',');
  }

  Number.prototype.toMoney = function(decimals, decimal_sep, thousands_sep)
{ 
   var n = this,
   c = isNaN(decimals) ? 2 : Math.abs(decimals), //if decimal is zero we must take it, it means user does not want to show any decimal
   d = decimal_sep || '.', //if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)

   /*
   according to [https://stackoverflow.com/questions/411352/how-best-to-determine-if-an-argument-is-not-sent-to-the-javascript-function]
   the fastest way to check for not defined parameter is to use typeof value === 'undefined' 
   rather than doing value === undefined.
   */   
   t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep, //if you don't want to use a thousands separator you can pass empty string as thousands_sep value

   sign = (n < 0) ? '-' : '',

   //extracting the absolute value of the integer part of the number and converting to string
   i = parseInt(n = Math.abs(n).toFixed(c)) + '', 

   j = ((j = i.length) > 3) ? j % 3 : 0; 
   return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : ''); 
}


function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const useStateWithLocalStorage = storageKey => {
    const [noticies, setNoticies] = React.useState(
      localStorage.getItem(storageKey) || ''
    );
   
    React.useEffect(() => {
      localStorage.setItem(storageKey, noticies);
    }, [noticies]);
   
    return [noticies, setNoticies];
  };


export default PledgeCounts;