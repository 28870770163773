import firebase from 'firebase'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD47WSYEJ2wZuW1cA67N9WxNZWJpcOHCaI",
  authDomain: "al-iman-fundraiser.firebaseapp.com",
  databaseURL: "https://al-iman-fundraiser.firebaseio.com",
  projectId: "al-iman-fundraiser",
  storageBucket: "al-iman-fundraiser.appspot.com",
  messagingSenderId: "960066121975",
  appId: "1:960066121975:web:a7f2884be22ffde5e663f2",
  measurementId: "G-32T2CBV4H6"
};
// const firebaseConfig = {
//     apiKey: "AIzaSyD47WSYEJ2wZuW1cA67N9WxNZWJpcOHCaI",
//     authDomain: "al-iman-fundraiser.firebaseapp.com",
//     databaseURL: "https://al-iman-fundraiser.firebaseio.com",
//     projectId: "al-iman-fundraiser",
//     storageBucket: "al-iman-fundraiser.appspot.com",
//     messagingSenderId: "960066121975",
//     appId: "1:960066121975:web:a7f2884be22ffde5e663f2",
//     measurementId: "G-32T2CBV4H6"

//     // apiKey: "AIzaSyBcLJdH3ZJHuMMc448nUgePYQX4V3xdid4",
//     // authDomain: "burlington-75ec3.firebaseapp.com",
//     // databaseURL: "https://burlington-75ec3.firebaseio.com",
//     // projectId: "burlington-75ec3",
//     // storageBucket: "burlington-75ec3.appspot.com",
//     // messagingSenderId: "918646248985",
//     // appId: "1:918646248985:web:dd354a951b0016a009bc88",
//     // measurementId: "G-RZQPNN084G"
//   };

  

  firebase.initializeApp(firebaseConfig);
export default firebase;