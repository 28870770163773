import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color:"whitesmoke" 
    },
  }));

const Header = () => {
    const classes = useStyles();
return (    
    <Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      <Box borderRadius={20} boxShadow={20} style={{background:'white', padding:'-10px'}}>
    <img className = "logo" src={require(`../../images/${process.env.REACT_APP_LOGO_FILE_PATH}`)} alt="logo"></img>
    </Box>
<Box fontWeight="fontWeightLight" fontSize={25} pt={3}>
      
          {process.env.REACT_APP_LOGO_TITLE}
    
        </Box>
        </div>
        </Container>
);


}



export default Header;

