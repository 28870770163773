import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    ListItemText: 
    {
        fontSize: 'larger'
    }
  }));


const PledgeToast = ({PrimaryText, SecondaryText}) => 
{
    const classes = useStyles();

  return (
    <ListItem style={{padding:0}}>
   
      <img style={{width:'75px'}} src={require(`../../images/${process.env.REACT_APP_LOGO_FILE_PATH}`)}/>
 
  <ListItemText ClassName={classes.ListItemText} primary={<Typography style={{color:'#3498db'}} variant="h6" ><em>{PrimaryText}</em></Typography>} secondary={<span style={{paddingLeft:'5px',color:'#34495e'}}>{SecondaryText}</span>} />
  </ListItem>
  )
}

export default PledgeToast;