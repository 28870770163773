import React, {useState, useEffect} from 'react';
import {HorizontalBar, Bar} from 'react-chartjs-2';
import firebase from '../../../firebase';
import Typography from '@material-ui/core/Typography';
import CurrencyFormat from 'react-currency-format';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor:'white',
    borderRadius:16,
    padding:theme.spacing(1)

  },
  
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0),

  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: '20px'
  },
  chartContainer: {
    position: 'relative',
    margin: 'auto',
    height: '9vh',
    width: '100%',
  }
  
}));




const PledgeBarChart = ({Goal}) => {

  const classes = useStyles();
  useEffect(()=> {

    var pledgesRef = firebase.database().ref('pledges');
    pledgesRef.on('value', function(data) {
        const value  = data.val();
        console.log('from value')
        console.log(value);
        if (value === null || typeof value === "undefined") return null;
        const keys = Object.keys(value);
        const values = Object.values(value);
        const valuesWithKeys = values.map(
          (value, i) =>
            ({
              amount:value.amount,
              id: keys[i]
            })
        );
  
  
        console.log('from pledge componenet')
     console.log(valuesWithKeys);


     var changedAmounts = [];
     valuesWithKeys.filter(v => !isNaN(v.amount) ).map(p => changedAmounts.push(p.amount) );


     setPledges(changedAmounts);       

      let updatedAmount =  changedAmounts.reduce((a, n) => (a + Number(n)), 0);
       setTotal(updatedAmount);

       var uniqueAmounts = [...new Set(changedAmounts)];
       var uniqueAmountCounts = [];
       uniqueAmounts.map( am => { 
        console.log(am);
        uniqueAmountCounts[am] = getOccurrence(changedAmounts, am)
          

        });

        setPledgeCounts(uniqueAmountCounts);
      console.log(uniqueAmountCounts);



     setChartData({
      labels: ['Total'],
      datasets: [
        {
           label: ['Total'],
        backgroundColor: [
                // 'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.5)',
                // 'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(255, 159, 64, 0.5)'
            ],
            borderColor: [
                // 'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                // 'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
          borderWidth: 1,
          hoverBackgroundColor: [
            // 'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            // 'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ],
          hoverBorderColor:  [
            // 'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            // 'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ],
          data: [updatedAmount]
        }
      ]
  
     
  
  
    });


     });
  
  }, []);


    
   const [total, setTotal] = useState(0);
   const [pledges, setPledges] = useState([]);
   const [chartData, setChartData] = useState({});
   const[pledgeCounts,setPledgeCounts] = useState({})



var chartOptions = {
  showScale: true,
  pointDot: true,
  showLines: false,
 maintainAspectRatio:false,
 responsive:true,
  title: {
      display: false,
      text: 'Chart.js Bar Chart'
  },

  legend: {
      display: false,
      labels: {
          boxWidth: 50,
          fontSize: 13,
          fontColor: '#bbb',
          padding: 5,
      }
  },

  scales: {
    yAxes: [{
        ticks: {
            beginAtZero:true,
            min: 0,
            max: Goal,
            fontSize: 15,    

        },
        
      }],
      xAxes:[{
        ticks:{
          beginAtZero:true,
          min: 0,
          max: parseInt(Goal) + 25000,
        fontSize: 15,
        callback: function(value, index, values) {
          if(parseInt(value) >= 1000){
            return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
            return '$' + value;
          }
        }
        }
      }]
   }
}


    
      return (      
        <Container component="main" max-width="xs">
        <CssBaseline />
        <Box width="xs" borderRadius={20}  boxShadow={20} style={{backgroundColor:'black', padding:0}}>
        <div className={classes.paper}>
    <div style={{width:'100%',display:'flex', justifyContent:'space-evenly'}}>
    <Button style={{backgroundColor:'#0984e3', color:'white',  fontSize:'18px'}}>{`Pledges: ${ money(total)} `}</Button>  <Button style={{backgroundColor:'#00b894', color:'white', fontSize:'18px'}}>{`Goal: ${ money(Goal)} `}</Button>
    </div>
    <div className={classes.chartContainer}>
      <HorizontalBar  data={chartData} options={chartOptions} ></HorizontalBar>
      </div>
      </div>
      </Box>
      </Container>
      )


}




function getOccurrence(array, value) {
  return array.filter((v) => (v === value)).length;
}



function money(amount)
{
  return '$'+Number(amount).toMoney(0,'.',',');
}

Number.prototype.toMoney = function(decimals, decimal_sep, thousands_sep)
{ 
 var n = this,
 c = isNaN(decimals) ? 2 : Math.abs(decimals), //if decimal is zero we must take it, it means user does not want to show any decimal
 d = decimal_sep || '.', //if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)

 /*
 according to [https://stackoverflow.com/questions/411352/how-best-to-determine-if-an-argument-is-not-sent-to-the-javascript-function]
 the fastest way to check for not defined parameter is to use typeof value === 'undefined' 
 rather than doing value === undefined.
 */   
 t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep, //if you don't want to use a thousands separator you can pass empty string as thousands_sep value

 sign = (n < 0) ? '-' : '',

 //extracting the absolute value of the integer part of the number and converting to string
 i = parseInt(n = Math.abs(n).toFixed(c)) + '', 

 j = ((j = i.length) > 3) ? j % 3 : 0; 
 return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : ''); 
}



  export default PledgeBarChart;