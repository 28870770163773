
import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Menuitem from '@material-ui/core/Menuitem';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import firebase from '../../firebase.js';
import {Bar} from 'react-chartjs-2';
import PledgeBarChart from '../charts/Bar/pledgeBarChart';
import { FirebaseDatabaseNode } from "@react-firebase/database";
import Header from '../header/header'
import Autocomplete from '@material-ui/lab/Autocomplete';

import {Formik, setNestedObjectValues} from 'formik';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ResponsiveEmbed  from 'react-responsive-embed'


function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {'Copyright © '}
      <Link color="inherit" href={process.env.REACT_APP_ORG_WEBSITE}>
        {process.env.REACT_APP_ORGANIZATION}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding:theme.spacing(5),
    width:'100%',
    borderRadius:20

  },

  video: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding:theme.spacing(5),
    width:'100%',
    borderRadius:20

  },

  banner: {
    color:"ghostwhite",
    backgroundColor:'#d35400',
    fontSize:25,
    padding:theme.spacing(1),
    marginBottom:theme.spacing(1),
    width:'100%',
    textAlign:'center'

  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0),
    
  },
  error: {
    color:'tomato'
    
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  option: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: '20px'
  },
  select: {
    margin: "20px 0px 0px 0px"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const Pledge = (props) => {
  const classes = useStyles();
   const [pledge, setPledge] = useState({
    firstName:"",
    lastName: "",
    email:"",
    phone:"",
    amount:"",
    paymentType:"",
    paymentFrequency:""
   });

   const [pledges, setPledges] = useState([]);
   const [open, setOpen] = React.useState(false);

   const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = (e, s) =>
   {
    setPledge({ ...pledge, [e.target.name]:e.target.value});
   }



   const submit = (e, s) =>
   {
    e.preventDefault();
     
    const pledgesRef = firebase.database().ref('pledges');
    pledgesRef.push(pledge);
    
   if(pledge.paymentFrequency === "One Time")
    {
      window.location.replace(`https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=paypal%40annorquranacademy.org&item_name=Annoor+School+Fundraiser&currency_code=USD&amount=${pledge.amount}&source=url`)
    }
   
   }


   const pledgeAmounts = [

    { amount: 50000, label: '$50,000' },
    { amount: 10000, label: '$10,000' },
    { amount: 5000, label: '$5,000' },
    { amount: 2500, label: '$2,500' },
    { amount: 2000, label: '$2,000' },
    { amount: 1500, label: '$1,500' },
    { amount: 1000, label: '$1,000' },
    { amount: 500, label: '$500' },
    { amount: 250, label: '$250' },
    { amount: 100, label: '$100' }
  ];




  return (

<Grid 
container
direction="row"
justify="center"
alignItems="top"
>

<Grid item xs={12}>
<Header/>
</Grid>

  <Grid item xs={12} sm={12} md={12} lg={6}>
  <div className={classes.video}>
    <Box  boxShadow={20} className={classes.banner}>{process.env.REACT_APP_EVENT_TITLE}</Box>
</div>
<ResponsiveEmbed src={process.env.REACT_APP_YOUTUBE_URL} allowFullScreen ratio='16:9' />
</Grid>



<Grid item>

    <Formik 
     initialValues={
      {
        firstName:"",
        lastName: "",
        email:"",
        phone:"",
        amount:"",
        paymentType:"",
        paymentFrequency:"",
     }}
     

     onSubmit={(values, {setSubmitting, resetForm}) => {
       values.approve = false;
      const pledgesRef = firebase.database().ref('pledges');
      pledgesRef.push(values);
      
   
      resetForm();
      
     if(values.paymentFrequency === "One Time" && values.paymentType === "Credit Card")
      {
        
       // window.location.replace('https://annoorquranacademy.square.site/product/2021-annual-fundraiser/22?cs=true&cst=true');

        window.location.replace(`https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=${process.env.REACT_APP_PAYPAL_EMAIL}&lc=US&item_name=${process.env.REACT_APP__PAYPAL_EVENT_TITLE}&no_note=0&cn=&curency_code=USD&amount=${values.amount}&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHosted`)

      //  window.location.replace(`https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=maher.alhertani%40alimanschool.org&item_name=Al-Iman+School+Fundraiser&currency_code=USD&amount=${values.amount}&source=url`)
      }
      else
      {
        setOpen(true);
      }
     

       

     }}

     

validationSchema = {Yup.object().shape({

  firstName: Yup.string().required("First Name is Required"),
        lastName:  Yup.string().required("Last Name is Required"),
        email: Yup.string().email("Please enter a valid email").required("Email is Required"),
        phone:Yup.string().required("Phone Number is Required").matches(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/, 'Phone number is not valid'),
        amount: Yup.number().required("Pledge Amount is Required").min(10, "Minimum is $10").max(100000,"Maximum is $100,000"),
        paymentType:Yup.string().required("Payment Type is Required"),
        paymentFrequency:Yup.string().required("Payment Frequency is Required")
})}
     
     >
        {
          props => { const {values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, isValid} = props;
        
        return (
          <Container component="main" maxWidth="sm">
          <CssBaseline />
           <Box width="sm" borderRadius={20} boxShadow={20}>
      <div className={classes.paper}>
  
      <Typography variant="h5">Pledge Form</Typography>
        <form className={`${classes.form}` } noValidate>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstName" 
            label="First Name"
            name="firstName"
            autoComplete="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error = {errors.firstName && touched.firstName}
          />
          {errors.firstName && touched.firstName && (
            <div className={classes.error}>{errors.firstName}</div>
          )}
           <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error = {errors.firstName && touched.firstName && true}
            />
            {errors.lastName && touched.lastName && (
              <div className={classes.error}>{errors.lastName}</div>
            )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error = {errors.email && touched.email}
            />
            {errors.email && touched.email && (
              <div className={classes.error}>{errors.email}</div>
            )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Phone Number"
            name="phone"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            error = {errors.phone && touched.phone}
            />
            {errors.phone && touched.phone && (
              <div className={classes.error}>{errors.phone}</div>
            )}


        <FormControl style={{margin: "20px 0px 0px 0px"}} fullWidth variant="outlined" >
        <TextField
        select
          native
          onChange={handleChange}
          onBlur={handleBlur}
          label="Pledge Amount"
          name="amount"
          startAdornment={<InputAdornment position="start"></InputAdornment>}
          variant="outlined"
          labelWidth={100}
          onBlur={handleBlur}
          value={values.amount}
          error = {errors.amount && touched.amount}
        >
          <Menuitem aria-label="None" ></Menuitem>
          <Menuitem value={50000}>$50,000 (or $4,166.67 per month)</Menuitem>
          <Menuitem value={25000} >$25,000 (or $2083.33 per month)</Menuitem>
          <Menuitem value={20000} >$20,000 (or $1666.67 per month)</Menuitem>
          <Menuitem value={10000} >$10,000 (or $833.33 per month)</Menuitem>
          <Menuitem value={5000}>$5,000 (or $416.67 per month)</Menuitem>
          <Menuitem value={2500}>$2,500 (or $208.33 per month)</Menuitem>
          <Menuitem value={2000}>$2,000 (or $166.67 per month)</Menuitem>
          <Menuitem value={1500}>$1,500 (or $125 per month)</Menuitem>
          <Menuitem value={1000}>$1,000 (or $83.33 per month)</Menuitem>
          <Menuitem value={500}>$500 (or $41.67 per month)</Menuitem>
          <Menuitem value={250}>$250 (or $20.83 per month)</Menuitem>
          <Menuitem value={100}>$100 (or $8.33 per month)</Menuitem>
     

          </TextField>
          {errors.amount && touched.amount && (
            <div className={classes.error}>{errors.amount}</div>
          )}
      </FormControl>

    
      <FormControl style={{margin: "20px 0px 0px 0px"}} fullWidth variant="outlined">
        <TextField
        select
          native
          value={values.paymentType}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Payment Type"
          name="paymentType"
          startAdornment={<InputAdornment position="start"></InputAdornment>}
          variant="outlined"
          labelWidth={100}
          onBlur={handleBlur}
          error = {errors.paymentType && touched.paymentType}
        >
          <Menuitem aria-label="None" ></Menuitem>
          <Menuitem value={'Credit Card'}>Credit Card</Menuitem>
          <Menuitem value={'Bank Draft'} >Bank Draft</Menuitem>
          <Menuitem value={'Check'}>Check</Menuitem>
          <Menuitem value={'Cash'}>Cash</Menuitem>
        </TextField>
        {errors.paymentType && touched.paymentType && (
            <div className={classes.error}>{errors.paymentType}</div>
          )}
      </FormControl>
      <FormControl style={{margin: "20px 0px 0px 0px"}} fullWidth variant="outlined">
        <TextField
        select
          native
          value={values.paymentFrequency}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Payment Frequency"
          name="paymentFrequency"
          startAdornment={<InputAdornment position="start"></InputAdornment>}
          variant="outlined"
          labelWidth={100}
          onBlur={handleBlur}
          error = {errors.paymentFrequency && touched.paymentFrequency}
        >
          <Menuitem aria-label="None" ></Menuitem>
          <Menuitem value={'One Time'}>One Time</Menuitem>
          <Menuitem value={'Recurring'} >Recurring (Monthly) </Menuitem>
        </TextField>
        {errors.paymentFrequency && touched.paymentFrequency && (
            <div className={classes.error}>{errors.paymentFrequency}</div>
          )}
      </FormControl>


          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            Submit Pledge
          </Button>
          <Grid container>
            <Grid item xs>
              <span href="#" variant="body2">
                Please fill out this form even if you have an exisitng pledge.
              </span>
            </Grid>
          </Grid>
        </form>
      
      </div>
      </Box>
      <Box style={{color:'white'}} mt={8}>
        <Copyright />
      </Box>
          </Container>
        )}}

    </Formik>
</Grid>


<Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"JakakAllahuKhairun for your Pledge!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {process.env.REACT_APP_ORGANIZATION} will follow up with you shortly to help you fulfill your pledge!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>


    </Grid>
       


  );
}

export default Pledge;

