import React from 'react';
import './App.css';
import firebase from './firebase.js';
import { FirebaseDatabaseProvider, FirebaseDatabaseNode } from "@react-firebase/database";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Pledge from './components/pledge/pledge';
import PledgeBarChart from './components/charts/Bar/pledgeBarChart';
import PledgeTable from './components/tables/pledgeTable';
import PledgeCounts from './components/tables/pledgeCounts/pledgeCounts'
import Container from '@material-ui/core/Button';
import Header from './components/header/header';
import AuthTest from './components/AuthTest';

const App = () => 
{
  return (
    <div id="App">
    
          
      <FirebaseDatabaseProvider firebase={firebase}>
          <BrowserRouter basename="/profiles">
          <Switch>
            <Route path="/chart" component={() => <PledgeBarChart Goal={process.env.REACT_APP_FUNDING_GOAL}/>}/>
            <Route path="/table" component={PledgeCounts} />
            <Route path="/test" component={AuthTest} />
            <Route path="/" component={Pledge} />
    
          </Switch>
      </BrowserRouter>


      </FirebaseDatabaseProvider>
    </div>
  );
}

export default App;
